var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('v-list',{staticClass:"local-document__select-type rounded"},[_c('v-subheader',[_vm._v("Тип документа")]),_c('v-list-item-group',{attrs:{"color":"indigo","mandatory":""},model:{value:(_vm.selectType),callback:function ($$v) {_vm.selectType=$$v},expression:"selectType"}},[_c('v-divider'),_vm._l((_vm.types),function(type,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"title":type.Name}},[_vm._v(_vm._s(type.Name))])],1),_c('v-list-item-action',[_c('v-badge',{attrs:{"bordered":"","color":"#657bb7","overlap":"","content":type.col}})],1)],1)})],2)],1),_c('base-list-view',{attrs:{"title":"Локальные документы","label-text-search":"Поиск документа","dataSource":_vm.filteredDocuments,"headers":_vm.headers,"loading":_vm.loading,"pageEditName":"LocalDocumentEdit","multiple-save":[
      { value: 'more', text: 'Создать документ' } ].concat( _vm.documentTemplatesData ),"group-by":"ActivitySection.Id","groupOpenType":"custom","groupKeyIds":_vm.groupKeyIds,"not-show-actions":_vm.readOnly ? ['add'] : [],"showDelete":!_vm.readOnly,"showCopy":!_vm.readOnly,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"item-class":function (item) {
        return _vm.pageType === 'my' && !item.Acquainted
          ? 'font-weight-bold'
          : '';
      }},on:{"onRefresh":_vm.init,"onDelete":_vm.del,"update:dataSource":function($event){_vm.filteredDocuments=$event},"update:data-source":function($event){_vm.filteredDocuments=$event},"add":_vm.add,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
      var group = ref.group;
      var items = ref.items;
return [_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.groupKey(group))+" ("+_vm._s(items.length)+")")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }