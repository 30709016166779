<template>
  <v-layout>
    <v-list class="local-document__select-type rounded">
      <v-subheader>Тип документа</v-subheader>
      <v-list-item-group v-model="selectType" color="indigo" mandatory>
        <v-divider></v-divider>
        <v-list-item v-for="(type, index) in types" :key="index">
          <v-list-item-content>
            <v-list-item-title :title="type.Name">{{
              type.Name
            }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-badge
              bordered
              color="#657bb7"
              overlap
              :content="type.col"
            ></v-badge>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <base-list-view
      title="Локальные документы"
      label-text-search="Поиск документа"
      @onRefresh="init"
      @onDelete="del"
      :dataSource.sync="filteredDocuments"
      :headers="headers"
      :loading="loading"
      pageEditName="LocalDocumentEdit"
      :multiple-save="[
        { value: 'more', text: 'Создать документ' },
        ...documentTemplatesData,
      ]"
      @add="add"
      group-by="ActivitySection.Id"
      groupOpenType="custom"
      :groupKeyIds="groupKeyIds"
      :not-show-actions="readOnly ? ['add'] : []"
      :showDelete="!readOnly"
      :showCopy="!readOnly"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :item-class="
        (item) => {
          return pageType === 'my' && !item.Acquainted
            ? 'font-weight-bold'
            : '';
        }
      "
    >
      <template #group.header="{ group, items }">
        <span class="subtitle-2"
          >{{ groupKey(group) }} ({{ items.length }})</span
        >
      </template>
    </base-list-view>
  </v-layout>
</template>

<script>
import { mapActions } from "vuex";
import Vue from "vue";
import ListEditMixin from "../../mixins/ListEditMixin";
import LocalDocumentService from "@/services/LocalDocumentService";
import BaseListView from "../../layouts/BaseListView.vue";
import { OrganizationDocumentStatus } from "../../data/enums";
import DocumentTemplateService from "../../services/document/DocumentTemplateService";
import Permissions from "../../data/permissions";

export default {
  name: "view-LocalDocuments",
  components: {
    BaseListView,
  },
  mixins: [ListEditMixin],
  data: function () {
    return {
      selectType: 0,
      sortBy: ["Date", "Num"],
      sortDesc: [true, true],
      DocumentPrint: {
        Content: null,
      },
      readOnly: !this.hasPermission(Permissions.OrganizationDocumentsEdit),
      apiService: LocalDocumentService,
      loading: true,
      headers: [
        {
          text: "Тип документа",
          value: "Type.Name",
          width: 220,
        },
        { text: "Номер", value: "Num", width: "1%" },
        {
          text: "Дата",
          value: "Date",
          dataType: "Date",
          width: "1%",
          displayText: Vue.filter("DateShort"),
        },
        { text: "Наименование", value: "Name", notGroup: true },
        {
          text: "Статус",
          value: "Status",
          dataType: "enum",
          options: OrganizationDocumentStatus,
          width: 180,
        },
      ],
      dataSource: [],
      documentTemplates: [],
      groupKeyIds: [],
    };
  },
  computed: {
    filteredDocuments: {
      get() {
        if (this.pageType === "my" && this.selectType === 1) {
          return this.dataSource.filter((e) => !e.Acquainted);
        }

        if (this.pageType === "all") {
          return this.dataSource.filter(
            (e) =>
              e.Status !== 0 &&
              (!this.selectType ||
                (this.types[this.selectType].Id >= 0 &&
                  e.Type &&
                  e.Type.Id === this.types[this.selectType].Id))
          );
        }

        return this.dataSource.filter(
          (e) =>
            !this.selectType ||
            (this.types[this.selectType].Id >= 0 &&
              e.Type &&
              e.Type.Id === this.types[this.selectType].Id)
        );
      },
      set(val) {
        for (let i = 0; i < val.length; i++) {
          const index = this.dataSource.findIndex((e) => e.Id === val[i].Id);

          if (index > -1) {
            this.$set(this.dataSource, index, val[i]);
          } else {
            this.dataSource.push(val[i]);
          }
        }
      },
    },
    pageType() {
      return this.currentRouter.params?.type ?? "my";
    },
    documentTemplatesData() {
      return this.documentTemplates
        .map((e) => ({ value: e.Id, text: e.Name }))
        .sort((a, b) => a.text?.localeCompare(b.text));
    },
    types() {
      const arr = [{ Id: -1, Name: "Все", col: this.dataSource.length }];

      if (this.pageType === "all") {
        arr[0].col = this.dataSource.filter((d) => d.Status !== 0).length;
      }

      if (this.pageType === "my")
        arr.push({
          Id: -2,
          Name: "Новые",
          col: this.dataSource.filter((e) => !e.Acquainted).length,
        });
      for (const type of this.$store.state.loaderDataSources
        .organizationDocumentTypes) {
        let col =
          this.dataSource?.filter((e) => e?.Type?.Id === type.Id).length ?? 0;

        if (this.pageType === "all") {
          col =
            this.dataSource?.filter(
              (e) => e?.Type?.Id === type.Id && e.Status !== 0
            ).length ?? 0;
        }

        if (col)
          arr.push({
            ...type,
            col,
          });
      }
      return arr;
    },
  },
  created() {
    this.LOAD_ORGGANIZATION_DOCUMENT_TYPES();
    this.LOAD_AUDIT_INDICATOR_CATEGORIES();
  },
  async mounted() {
    this.init();
  },
  methods: {
    ...mapActions("loaderDataSources", ["LOAD_ORGGANIZATION_DOCUMENT_TYPES"]),
    ...mapActions(["LOAD_AUDIT_INDICATOR_CATEGORIES"]),
    async documentPrint(item) {
      if (item.Id === this.DocumentPrint?.Id) {
        this.$refs.rich.documentPrint();
        return;
      }

      const { data } = await LocalDocumentService.show(item.Id);
      this.DocumentPrint = data;
    },
    async add(item) {
      await this.refreshTemplates();
      if (item.value === "more") {
        if (this.documentTemplatesData.length === 0) {
          this.$alert("Необходимо создать хотя бы один шаблон документа.");
        }
        return;
      }
      if (
        this.documentTemplatesData.findIndex((d) => d.value === item.value) !==
        -1
      )
        this.$router.push({
          name: "LocalDocumentEdit",
          params: {
            objectId: 0,
          },
          query: {
            templateId: item.value,
            type: "create",
          },
        });
      else {
        Vue.prototype.$alert("Шаблон не найден, возможно он был удален.");
      }
    },
    async refreshTemplates() {
      this.documentTemplates = (
        await DocumentTemplateService.get(null, false, true)
      ).data;
    },
    DataFromServerNormalize(data) {
      if (this.pageType === "my") {
        data = data.filter((e) => e.Status !== 6);
      }
      return data;
    },
    async init() {
      try {
        this.loading = true;
        this.refreshTemplates();
        const res = await LocalDocumentService.get({
          type: this.pageType,
          participance: !this.hasPermission(
            Permissions.OrganizationDocumentViewAll
          ),
        });
        this.dataSource = this.DataFromServerNormalize(res.data);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    groupKey(id) {
      const category = this.$store.state.auditIndicatorCategories.find(
        (category) => category.Id === id
      );
      if (category) return category.Name;
    },
    async expand(toggle, isOpen, group) {
      const id = group.toString();

      // проверка перед нажатием
      this.groupKeyIds = isOpen
        ? this.groupKeyIds.filter((f) => f !== id)
        : [...new Set([...this.groupKeyIds, id])];
      toggle();
    },
  },
};
</script>
<style lang="scss">
.local-document__select-type {
  width: 275px;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 2px 8px 30px rgb(35 52 107 / 5%);
  .v-list-item__content {
    color: #919ab5;
  }
}
</style>
